import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db, storage, ACCOUNTS_SEARCH_INDEX } from "@/firebase";
import { defaultAccountCounts } from "@/typings/defaults/accounts";
import { SearchIndex } from "algoliasearch/lite";

interface DataWatcher {
  data: Watcher | null;
  counts: Watcher | null;
  limits: Watcher | null;
  planLimits: Watcher | null;
  overrideLimits: Watcher | null;
  settings: Watcher | null;
  kaijuInfo: Watcher | null;
}
function newDataWatcher(): DataWatcher {
  return {
    data: null,
    counts: null,
    limits: null,
    planLimits: null,
    overrideLimits: null,
    settings: null,
    kaijuInfo: null
  };
}

let accountsSearchIndex: SearchIndex | null = null;

const watchers: Dictionary<DataWatcher> = {};

const getDefaultState = () => ({
  all: {} as Dictionary<AccountStoreEntry>
});

const AccountsStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_ACCOUNT(
      state,
      { id, account }: { id: string; account: Partial<AccountStoreEntry> }
    ) {
      Vue.set(state.all, id, Object.assign({}, state.all[id] ?? {}, account));
    },
    ADD_OR_UPDATE_SEVERAL_ACCOUNTS(state, newOrUpdatedAccounts: Dictionary<AccountStoreEntry>) {
      const allAccounts = { ...state.all };
      Object.entries(newOrUpdatedAccounts).map(([id, account]) => {
        allAccounts[id] = Object.assign({}, state.all[id] ?? {}, account);
      });
      Vue.set(state, "all", allAccounts);
    }
  },
  actions: {
    watchAccount(context, accountId: string): void {
      const { dispatch } = actionContext(context);
      if (!accountId) {
        return;
      }
      void dispatch.watchAccountData(accountId);
      void dispatch.watchAccountCounts(accountId);
      void dispatch.watchAccountLimits(accountId);
      void dispatch.watchAccountSettings(accountId);
      void dispatch.watchAccountKaijuInfo(accountId);
    },
    watchAccountData(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (!accountId) {
        return;
      }
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId] as DataWatcher;
      if (watcher.data) {
        watcher.data();
      }
      watcher.data = db
        .collection("accounts")
        .doc(accountId)
        .onSnapshot(snapshot => {
          const accountData = snapshot.data();
          commit.ADD_OR_UPDATE_ACCOUNT({
            id: accountId,
            account: { ...accountData, id: accountId }
          });
        }, console.error);
      watchers[accountId] = watcher;
    },
    stopWatchingAccountData(context, accountId: string): void {
      if (!accountId) {
        return;
      }
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId];
      if (watcher?.data) {
        watcher.data();
      }
    },
    watchAccountCounts(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId] as DataWatcher;
      if (watcher.counts) {
        watcher.counts();
      }
      watcher.counts = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc("counts")
        .onSnapshot(countSnapshot => {
          commit.ADD_OR_UPDATE_ACCOUNT({
            id: accountId,
            account: { counts: (countSnapshot.data() as Account.Counts) ?? defaultAccountCounts() }
          });
        }, console.error);
      watchers[accountId] = watcher;
    },
    watchAccountLimits(context, accountId: string): void {
      const { dispatch } = actionContext(context);
      void dispatch.watchLimits({ accountId, limitType: "limits" });
    },
    watchAccountOverrideLimits(context, accountId: string): void {
      const { dispatch } = actionContext(context);
      void dispatch.watchLimits({ accountId, limitType: "overrideLimits" });
    },
    watchAccountPlanLimits(context, accountId: string): void {
      const { dispatch } = actionContext(context);
      void dispatch.watchLimits({ accountId, limitType: "planLimits" });
    },
    watchLimits(
      context,
      {
        accountId,
        limitType
      }: { accountId: string; limitType: "limits" | "planLimits" | "overrideLimits" }
    ): void {
      const { commit } = actionContext(context);
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId] as DataWatcher;
      let limitWatcher = watcher[limitType];
      if (limitWatcher) {
        limitWatcher();
      }
      limitWatcher = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc(limitType)
        .onSnapshot(limitsSnapshot => {
          commit.ADD_OR_UPDATE_ACCOUNT({
            id: accountId,
            account: {
              [limitType]: limitsSnapshot.data() as Account.Limits
            }
          });
        }, console.error);
      watchers[accountId] = watcher;
    },
    watchAccountSettings(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId] as DataWatcher;
      if (watcher.settings) {
        watcher.settings();
      }
      watcher.settings = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc("settings")
        .onSnapshot(settingsSnapshot => {
          commit.ADD_OR_UPDATE_ACCOUNT({
            id: accountId,
            account: { settings: (settingsSnapshot.data() as Account.Settings) ?? {} }
          });
        }, console.error);
      watchers[accountId] = watcher;
    },
    watchAccountKaijuInfo(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (!watchers[accountId]) {
        watchers[accountId] = newDataWatcher();
      }
      const watcher = watchers[accountId] as DataWatcher;
      if (watcher.kaijuInfo) {
        watcher.kaijuInfo();
      }
      watcher.kaijuInfo = db
        .collection("accounts")
        .doc(accountId)
        .collection("kaijuData")
        .doc("info")
        .onSnapshot(kaijuInfoSnapshot => {
          commit.ADD_OR_UPDATE_ACCOUNT({
            id: accountId,
            account: { kaijuInfo: (kaijuInfoSnapshot.data() as Kaiju.Info) ?? {} }
          });
        }, console.error);
      watchers[accountId] = watcher;
    },
    loadLogo(
      context,
      { accountId, type, fileName }: { accountId: string; type: "logoUrl"; fileName: string }
    ): Promise<void> {
      const { commit } = actionContext(context);
      return storage
        .ref(`logos/${accountId}/${fileName}`)
        .getDownloadURL()
        .then((downloadUrl: string) => {
          const accountUpdates: Partial<AccountStoreEntry> = {};
          accountUpdates[type] = downloadUrl;
          commit.ADD_OR_UPDATE_ACCOUNT({ id: accountId, account: accountUpdates });
        })
        .catch(console.error);
    },
    async getAccountsSearchIndex(context): Promise<SearchIndex> {
      if (accountsSearchIndex) {
        return accountsSearchIndex;
      } else {
        const { rootDispatch } = actionContext(context);
        try {
          console.log("getAccountsSearchIndex", "getting new index");
          const searchClient = await rootDispatch.getSearchClient();
          const searchIndex = searchClient.initIndex(ACCOUNTS_SEARCH_INDEX);
          accountsSearchIndex = searchIndex;
          console.log(ACCOUNTS_SEARCH_INDEX);
          return searchIndex;
        } catch (error) {
          accountsSearchIndex = null;
          throw error;
        }
      }
    }
  }
});

export default AccountsStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, AccountsStore);
