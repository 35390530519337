import type { Product } from "@/typings/stripe";
import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db } from "../../firebase";

let productToWatch: WatcherCollection = {};
function resetPlanStore() {
  Object.values(productToWatch).forEach(unsubscribe => unsubscribe && unsubscribe());
  productToWatch = {};
}

const getDefaultState = () => ({
  items: {} as Dictionary<Product>
});

const ProductStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_PRODUCT(state, { id, product }: { id: string; product: Product }) {
      Vue.set(state.items, id, product);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetPlanStore();
    }
  },
  actions: {
    watchProduct(context, planId: string) {
      const { commit } = actionContext(context);
      if (productToWatch[planId]) {
        return;
      } else {
        console.log("watchProduct", planId);
        productToWatch[planId] = db
          .collection("global-admin")
          .doc("stripe")
          .collection("products")
          .doc(planId)
          .onSnapshot(productSnapshot => {
            const product = { id: productSnapshot.id, ...productSnapshot.data() } as Product;
            const { id } = productSnapshot;
            commit.ADD_OR_UPDATE_PRODUCT({ id, product });
          }, console.error);
      }
    }
  }
});

export default ProductStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, ProductStore);
