






import Vue, { PropType } from "vue";

import DefaultLogo from "@/icons/ReferralReactor.vue";

export default Vue.extend({
  name: "Logo",
  components: {
    DefaultLogo
  },
  props: {
    // "old" will show the old Referral Reactor logo.
    displayMode: { type: String as PropType<"default" | "light" | "dark" | "old">, default: null }
  },
  computed: {
    // logoUrl(): string | null {
    //   return this.$store.direct.state.ui.logoUrl;
    // },
    colorScheme(): "light" | "dark" {
      return this.$store.direct.state.ui.preferredColorScheme;
    }
  }
});
