import Vue from "vue";
import orderBy from "lodash/orderBy";
import { defineModule } from "direct-vuex";
import { moduleActionContext, moduleGetterContext } from "../index";
import { db } from "../../firebase";
import withoutUndefinedProperties from "@/helpers/withoutUndefinedProperties";

const getDefaultState = () => ({
  count: 0, // From Firestore
  all: {} as Dictionary<Messages.UserFeedback>
});

let messagesWatchers: WatcherCollection = {};
function resetMessagesStore() {
  Object.values(messagesWatchers).forEach(unsubscribe => unsubscribe && unsubscribe());
  messagesWatchers = {};
}

const MessagesStore = defineModule({
  state: getDefaultState(),
  mutations: {
    SET_FEEDBACK_MESSAGES_COUNT(state, count: number) {
      state.count = count;
    },
    ADD_OR_UPDATE_MESSAGE(state, newMessage: Messages.UserFeedback & { id: string }) {
      state.all[newMessage.id] = newMessage;
    },
    ADD_OR_UPDATE_SEVERAL_MESSAGES(state, newOrUpdatedMessages: Dictionary<Messages.UserFeedback>) {
      state.all = { ...state.all, ...newOrUpdatedMessages };
    },
    STOP_WATCHING_MESSAGES() {
      Object.values(messagesWatchers).forEach(unsubscribe => unsubscribe && unsubscribe());
      messagesWatchers = {};
    },
    FORGET_MESSAGE(state, messageId: string) {
      Vue.delete(state.all, messageId);
    },
    FORGET_ALL_MESSAGES(state) {
      Object.assign(state, getDefaultState());
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetMessagesStore();
    }
  },
  actions: {
    watchMessages(context): void {
      const { commit, rootGetters } = actionContext(context);
      commit.STOP_WATCHING_MESSAGES();
      if (!rootGetters.currentUserHasGlobalAccess) {
        return;
      }
      const messagesDoc = db.collection("global-admin").doc("userFeedbackMessages");

      const countsWatcher = messagesDoc.onSnapshot(snap => {
        console.log("messagesDoc", snap.exists, snap.data());
        const count = Number(snap.data()?.count ?? 0);
        commit.SET_FEEDBACK_MESSAGES_COUNT(count);
      }, console.error);
      messagesWatchers.count = countsWatcher;

      messagesWatchers.allMessages = messagesDoc.collection("messages").onSnapshot(snap => {
        if (snap.empty) {
          return commit.FORGET_ALL_MESSAGES();
        }

        const newOrUpdatedMessages: Dictionary<Messages.UserFeedback & { id: string }> = {};

        snap.docChanges().forEach(change => {
          if (change.type === "removed") {
            commit.FORGET_MESSAGE(change.doc.id);
          } else {
            newOrUpdatedMessages[change.doc.id] = {
              ...(change.doc.data() as Messages.UserFeedback),
              id: change.doc.id
            };
          }
        });

        commit.ADD_OR_UPDATE_SEVERAL_MESSAGES(newOrUpdatedMessages);
      });
    },
    async setMessageArchived(
      context,
      { messageId, isArchived }: { messageId: string; isArchived: boolean }
    ): Promise<void> {
      const { state } = actionContext(context);
      if (!messageId || typeof messageId !== "string" || messageId === "") {
        throw new Error(`No valid messageId provided. Got: ${messageId}`);
      }
      if (isArchived === undefined || typeof isArchived !== "boolean") {
        throw new Error(
          `Must specify a boolean value for isArchived. Got: ${JSON.stringify(isArchived)}`
        );
      }
      const message = state.all[messageId];
      if (!message || message.isArchived === isArchived) {
        // If the message is already at archived properly, return!
        return;
      }
      return db
        .collection("global-admin")
        .doc("userFeedbackMessages")
        .collection("messages")
        .doc(messageId)
        .update({ isArchived });
    },
    archiveMessage(context, messageId: string): Promise<void> {
      const { dispatch } = actionContext(context);
      return dispatch.setMessageArchived({ messageId, isArchived: true });
    },
    unarchiveMessage(context, messageId: string): Promise<void> {
      const { dispatch } = actionContext(context);
      return dispatch.setMessageArchived({ messageId, isArchived: false });
    },
    deleteMessage(context, messageId: string): Promise<void> {
      if (!messageId || typeof messageId !== "string" || messageId === "") {
        throw new Error(`No valid messageId provided. Got: ${messageId}`);
      }
      return db
        .collection("global-admin")
        .doc("userFeedbackMessages")
        .collection("messages")
        .doc(messageId)
        .delete();
    }
  },
  getters: {
    allMessages(state): Messages.UserFeedback[] {
      return orderBy(withoutUndefinedProperties(state.all), "sentAt", "asc");
    },
    allMessagesCount(state): number {
      return state.count;
    },
    archivedMessages(state): Messages.UserFeedback[] {
      return Object.values(withoutUndefinedProperties(state.all)).filter(msg => msg?.isArchived);
    },
    archivedMessagesCount(...args): number {
      const { getters } = getterContext(args);
      return getters.archivedMessages.length;
    },
    unarchivedMessages(state): Messages.UserFeedback[] {
      return Object.values(withoutUndefinedProperties(state.all)).filter(
        msg => msg && !msg.isArchived
      );
    },
    unarchivedMessagesCount(...args): number {
      const { getters } = getterContext(args);
      return getters.unarchivedMessages.length;
    }
  }
});

export default MessagesStore;

type GetterContextArgs = Parameters<typeof moduleGetterContext>[0];
const getterContext = (args: GetterContextArgs) => moduleGetterContext(args, MessagesStore);

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, MessagesStore);
