import type { Route, NavigationGuard } from "vue-router";
import Vue from "vue";
import VueRouter from "vue-router";

import Core from "./Core.vue";
import Login from "./views/Login.vue";
import EmptyRoute from "@/components/EmptyRoute.vue";

import { auth } from "./firebase";
import store from "./store";

const App = () => import("./views/App.vue");
const Home = () => import("./views/Home.vue");

const Accounts = () => import("./views/accounts/Accounts.vue");
const AccountView = () => import("./views/accounts/AccountView.vue");
const AccountUsers = () => import("./views/accounts/AccountUsers.vue");
const AccountInvitationCodes = () => import("./views/accounts/AccountInvitationCodes.vue");
const AccountDetails = () => import("./views/accounts/AccountDetails.vue");
const AccountKaiju = () => import("./views/accounts/AccountKaiju.vue");
const AccountAdd = () => import("./views/accounts/AccountAdd.vue");

const ActiveAccountsReport = () => import("./views/ActiveAccountsReport.vue");
const Backups = () => import("./views/Backups.vue");

const AllMessages = () => import("./views/messages/AllMessages.vue");
const ArchivedMessages = () => import("./views/messages/ArchivedMessages.vue");
const MessageView = () => import("./views/messages/MessageView.vue");
const Signups = () => import("./views/Signups.vue");

// const UserView = loadAppPage("users/UserView");

Vue.use(VueRouter);

function whenAuthInfoHasLoaded(
  to: Route,
  from: Route,
  next: (to?: string | false | void | Location | ((vm: Vue) => unknown) | undefined) => void
) {
  // Waits for auth info to have been loaded before doing any routing
  function proceed() {
    if (store.state.ui.authInfoHasLoaded) {
      next();
    }
  }
  if (!store.state.ui.authInfoHasLoaded) {
    // Loading auth info...
    console.log("Waiting for auth info to load...");
    const unwatch = store.original.watch(
      state => state.ui.authInfoHasLoaded,
      value => {
        console.log("state.ui.authInfoHasLoaded", value);
        if (value === true) {
          unwatch();
          proceed();
        }
      }
    );
  } else {
    // Loaded!
    proceed();
  }
}

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Core,
      redirect: "/app",
      beforeEnter: whenAuthInfoHasLoaded as NavigationGuard<Vue>,
      children: [
        {
          path: "login",
          component: Login,
          beforeEnter(to, from, next) {
            whenAuthInfoHasLoaded(to, from, () => {
              if (auth.currentUser) {
                next("/app");
              } else {
                next();
              }
            });
          },
          meta: {
            title: (): TitleObject => "Login"
          }
        },
        {
          path: "logout",
          async beforeEnter(to, from, next) {
            store.commit.LOGOUT();
            if (auth.currentUser == null) {
              next("/login");
              return;
            }
            // Log out of Firebase
            try {
              await auth.signOut();
              console.log("LOGGED OUT");
              next("/login");
            } catch (error) {
              console.error("Error logging out: ", error);
            }
          }
        },
        {
          path: "app",
          component: App,
          beforeEnter(to, from, next) {
            if (auth.currentUser) {
              next();
            } else {
              next("/login");
            }
          },
          children: [
            {
              path: "",
              component: Home,
              meta: {
                title: (): TitleObject => "Home"
              }
            },
            {
              path: "accounts",
              component: EmptyRoute,
              children: [
                {
                  path: "",
                  component: Accounts,
                  meta: {
                    title: (): TitleObject => "Reactors"
                  }
                },
                {
                  path: "add",
                  component: AccountAdd,
                  meta: {
                    title: (): TitleObject => "Add Reactor Details"
                  }
                },
                {
                  path: ":accountId",
                  component: AccountView,
                  props: true,
                  meta: {
                    title: (): TitleObject => "Reactor"
                  },
                  children: [
                    {
                      path: "",
                      component: AccountDetails
                    },
                    {
                      path: "invitation-codes",
                      props: true,
                      component: AccountInvitationCodes,
                      meta: {
                        title: (): TitleObject => "Reactor Invitation Codes"
                      }
                    },
                    {
                      path: "users",
                      component: AccountUsers,
                      props: true,
                      meta: {
                        title: (): TitleObject => "Reactor Users"
                      }
                    },
                    {
                      path: "kaiju",
                      component: AccountKaiju,
                      props: true,
                      meta: {
                        title: (): TitleObject => "Reactor Kaiju Data"
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: "messages",
              component: AllMessages,
              meta: {
                title: () => "Messages"
              }
            },
            {
              path: "messages/archive",
              component: ArchivedMessages,
              meta: {
                title: (): TitleObject => "Message Archive"
              }
            },
            {
              path: "messages/:messageId",
              props: true,
              component: MessageView,
              meta: {
                title: (): TitleObject => "View Message"
              }
            },
            {
              path: "signups",
              component: EmptyRoute,
              meta: {
                title: () => "Signups"
              },
              children: [
                {
                  path: "",
                  component: Signups,
                  meta: {
                    title: () => "List"
                  }
                }
                // {
                //   path: ":signupId",
                //   component: SignupDetail,
                //   meta: {
                //     title: () => "Detail"
                //   }
                // }
              ]
            },
            // {
            //   path: "users/:userId",
            //   props: true,
            //   component: UserView,
            //   meta: {
            //     title: (): TitleObject => "User Details"
            //   }
            // },
            {
              path: "backups",
              component: Backups,
              meta: {
                title: (): TitleObject => "Backups"
              }
            },
            {
              path: "active-accounts-report",
              component: ActiveAccountsReport,
              meta: {
                title: (): TitleObject => "Active Accounts Report"
              }
            }
          ]
        },
        { path: "*", redirect: "/app" }
      ]
    }
  ]
});

type TitleRetriever = { navbar?: boolean; title: string | ((arg0: Route) => string) };
type TitleObject = TitleRetriever | string | null | undefined;

function isTitleObject(toBeDetermined: unknown): toBeDetermined is TitleObject {
  if (
    toBeDetermined === null ||
    toBeDetermined === undefined ||
    typeof toBeDetermined === "string"
  ) {
    return true;
  }
  if (toBeDetermined && typeof toBeDetermined === "object" && "title" in toBeDetermined) {
    return true;
  }
  return false;
}

const applicationTitle = "Referral Reactor Admin";
router.afterEach(to => {
  Vue.nextTick(() => {
    let title = applicationTitle;
    if (
      to.meta &&
      isTitleObject(to.meta) &&
      typeof to.meta !== "string" &&
      to.meta?.title &&
      typeof to.meta.title === "function"
    ) {
      const titleObject = to.meta.title(to) as TitleObject;

      // If we got a plain string, use it for document title.
      if (titleObject && typeof titleObject === "string") {
        title = titleObject + " - " + title;
        // store.commit.UPDATE_PAGE_TITLE("");

        // If we got an object with a `navbar` key set to `true`, use it for document and navbar title.
      } else if (
        titleObject &&
        typeof titleObject !== "string" &&
        titleObject.navbar === true &&
        typeof titleObject.title === "string"
      ) {
        title = titleObject.title + " - " + title;
        // store.commit.UPDATE_PAGE_TITLE(titleObject.title);
      }
    } else {
      document.title = title;
    }
  });
  window.scrollTo(0, 0);
});

export default router;
