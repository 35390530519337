import Vue from "vue";
import moment from "moment";
import startCase from "lodash/startCase";

export function ifempty(value: string, valueIfEmpty: string): string {
  if (value) {
    return value;
  } else {
    return valueIfEmpty;
  }
}
Vue.filter("ifempty", ifempty);

export function urlSafe(value: string): string {
  return window.encodeURIComponent(value);
}
Vue.filter("urlSafe", urlSafe);

export function toFileSize(value: unknown): string {
  let bytes = Number(value);
  const decimalConvention = true;

  const thresh = decimalConvention ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = decimalConvention
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u] || ""}`;
}
Vue.filter("toFileSize", toFileSize);

export function wordsFromCamelCase(value: string): string {
  // Translate camelCase to Capitalized Words
  return (
    String(value)
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, str => str.toUpperCase())
  );
}
Vue.filter("wordsFromCamelCase", wordsFromCamelCase);

export function durationSince(value: Date): string {
  return moment.duration(moment(value).diff(moment())).humanize();
}
Vue.filter("durationSince", durationSince);

export function relativeFromNow(value: Date): string {
  return moment(value).fromNow();
}
Vue.filter("relativeFromNow", relativeFromNow);

export function relativeToNow(value: Date): string {
  return moment(value).toNow();
}
Vue.filter("relativeToNow", relativeToNow);

export function toTimestamp(value: Date): string {
  return moment(value).format("hh:mm A on dddd MMMM Do, YYYY");
}
Vue.filter("toTimestamp", toTimestamp);

export function toCompactTimestamp(value: Date): string {
  return moment(value).format("hh:mm A MM/DD/YY");
}
Vue.filter("toCompactTimestamp", toCompactTimestamp);

export function toTime(value: Date): string {
  return moment(value).format("h:mm[ ]A");
}
Vue.filter("toTime", toTime);

export function toDate(value: Date): string {
  return moment(value).format("MM/DD/YY");
}
Vue.filter("toDate", toDate);

export function toCSVDate(value: Date): string {
  return moment(value).format("YYYY-MM-D");
}
Vue.filter("toCSVDate", toCSVDate);

export function toWordyDate(value: Date): string {
  return moment(value).format("D MMMM, YYYY");
}
Vue.filter("toWordyDate", toWordyDate);

export function toCalendarDate(value: Date): string {
  return moment(value).calendar(moment(Date()), {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "MM/DD/YY"
  });
}
Vue.filter("toCalendarDate", toCalendarDate);

export function toSpelledOutDate(value: Date): string {
  return moment(value).format("MMMM[ ]Do,[ ]YYYY");
}
Vue.filter("toSpelledOutDate", toSpelledOutDate);

export function toTimeDate(value: Date): string {
  return moment(value.toISOString()).format("h:mm[ ]A on MMMM[ ]Do,[ ]YYYY");
}
Vue.filter("toTimeDate", toTimeDate);

export function distanceMetersToKM(value: number): string {
  return `${Math.round(value / 1000)} km`;
}
Vue.filter("distanceMetersToKM", distanceMetersToKM);

export function distanceMetersToMI(value: number): string {
  return `${Math.round(value / 1609.344)} mi`;
}
Vue.filter("distanceMetersToMI", distanceMetersToMI);

Vue.filter("startCase", startCase);

export function withIndefiniteArticle(value: string): string {
  if (typeof value !== "string" || value.length == 0) {
    return value;
  }

  const firstLetter = value[0];
  if (
    firstLetter === "a" ||
    firstLetter === "e" ||
    firstLetter === "i" ||
    firstLetter === "o" ||
    firstLetter === "u"
  ) {
    return `an ${value}`;
  }
  return `a ${value}`;
}
Vue.filter("withIndefiniteArticle", withIndefiniteArticle);

export function currency(amount: number): string {
  const amountNumber = Number(amount);
  if (Number.isNaN(amountNumber)) {
    return "$0";
  } else {
    return "$" + amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
}
Vue.filter("currency", currency);

export function orZero<T>(amount: T): T | 0 {
  return amount ? amount : 0;
}
Vue.filter("orZero", orZero);

export function orUnlimited<T>(amount: T | null): T | string {
  return amount === null ? "\u221e" : amount;
}
Vue.filter("orUnlimited", orUnlimited);

export function capitalize(value: string): string {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter("capitalize", capitalize);

function shekelAsUnit(value: Shekel.Amount): number {
  switch (value.scale) {
    case 0:
      return value.amount;
    case 1:
      return value.amount / 10;
    case 2:
      return value.amount / 100;
    case 3:
      return value.amount / 1000;
    case 4:
      return value.amount / 10000;
    case 5:
      return value.amount / 100000;
    case 6:
      return value.amount / 1000000;
    default:
      return value.amount / Math.pow(10, value.scale);
  }
}

function shekelAsUnitInteger(value: Shekel.Amount): number {
  return Math.trunc(shekelAsUnit(value));
}

export function shekelFormat(value: Shekel.Amount): string {
  return currency(shekelAsUnitInteger(value));
}
Vue.filter("shekelFormat", shekelFormat);
