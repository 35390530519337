export default function withoutUndefinedProperties<V>(
  originalObject: Record<string, V | undefined>
): Record<string, V> {
  return Object.entries(originalObject).reduce((newObject, [key, value]) => {
    if (value === undefined) {
      return newObject;
    }
    newObject[key] = value;
    return newObject;
  }, {} as Record<string, V>);
}
