export function createShekelAmount(
  amount: number,
  currency: Shekel.Currency,
  scale: number
): Shekel.Amount {
  return {
    amount,
    currency,
    scale
  };
}

export function defaultKaijuInfo(): Kaiju.Info {
  return {
    enrolled: false,
    currentStatus: "DISABLED",
    preferredDepositMethod: "UNKNOWN",
    fundsBalance: createShekelAmount(0, "USD", 2)
  };
}
