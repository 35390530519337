import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db } from "@/firebase";

const getDefaultState = () => ({
  items: {} as Dictionary<Account.User>,
  numberOfUsers: 0
});

let usersToWatch: WatcherCollection = {};
function resetUserStore() {
  Object.values(usersToWatch).forEach(unsubscribe => unsubscribe && unsubscribe());
  usersToWatch = {};
}

const UserStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_USER(state, { id, user }: { id: string; user: Account.User }) {
      Vue.set(state.items, id, user);
    },
    ADD_OR_UPDATE_SEVERAL_USERS(state, newOrUpdatedUsers: Dictionary<Account.User>) {
      Vue.set(state, "items", { ...state.items, ...newOrUpdatedUsers });
    },
    FORGET_USER(state, userToRemove: string) {
      Vue.delete(state.items, userToRemove);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetUserStore();
    }
  },
  actions: {
    watchUser(context, userId: string): void {
      const { commit } = actionContext(context);
      if (usersToWatch[userId]) {
        return;
      } else {
        usersToWatch[userId] = db
          .collection("users")
          .doc(userId)
          .onSnapshot(
            userSnapshot => {
              const user = { id: userSnapshot.id, ...userSnapshot.data() } as Account.User;
              const { id } = userSnapshot;
              commit.ADD_OR_UPDATE_USER({ id, user });
            },
            error => console.error(error)
          );
      }
    },
    unwatchUser(context, userId: string): void {
      if (!userId) {
        return;
      }
      const watcher = usersToWatch[userId];
      if (watcher) {
        watcher();
        delete usersToWatch[userId];
      }
    }
  }
});

export default UserStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, UserStore);
