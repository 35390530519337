import type { GettersImpl } from "direct-vuex";
import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";

import {
  AccountsStore,
  AccountUserStore,
  BackupStore,
  CompanyStore,
  CurrentUserStore,
  KaijuStore,
  ProductStore,
  MessagesStore,
  UserStore,
  UserInterfaceStore
} from "./modules";

Vue.use(Vuex);

// Weird backflips for getter typing
// See https://stackoverflow.com/a/63746513/3799856

export type RootGetters = typeof CurrentUserStore.getters &
  typeof MessagesStore.getters &
  typeof UserInterfaceStore.getters;

export type RootState = {
  accounts: typeof AccountsStore.state;
  accountUsers: typeof AccountUserStore.state;
  backups: typeof BackupStore.state;
  companies: typeof CompanyStore.state;
  currentUser: typeof CurrentUserStore.state;
  kaiju: typeof KaijuStore.state;
  messages: typeof MessagesStore.state;
  products: typeof ProductStore.state;
  ui: typeof UserInterfaceStore.state;
  users: typeof UserStore.state;
};

// eslint-disable-next-line @typescript-eslint/unbound-method
const { store, moduleActionContext, moduleGetterContext } = createDirectStore({
  strict: true,
  modules: {
    accounts: AccountsStore,
    accountUsers: AccountUserStore,
    backups: BackupStore,
    companies: CompanyStore,
    currentUser: CurrentUserStore,
    kaiju: KaijuStore,
    messages: MessagesStore,
    products: ProductStore,
    ui: UserInterfaceStore,
    users: UserStore
  },
  getters: {} as RootGetters & GettersImpl<RootState, RootGetters>
});

export default store;

export type State = typeof store.state;
export type Getters = typeof store.getters;

export { moduleActionContext, moduleGetterContext };

export type ReferralReactorAdminStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: ReferralReactorAdminStore;
  }
}
