import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { firestore } from "@/firebase";

let backupsWatcher: Watcher | null = null;
function stopWatchingBackups() {
  if (backupsWatcher) {
    backupsWatcher();
    backupsWatcher = null;
  }
}

const getDefaultState = () => ({
  count: null,
  all: [] as BackupDocument[]
});

const BackupsStore = defineModule({
  state: getDefaultState(),
  mutations: {
    UPDATE_BACKUP_COUNT(state, count: number) {
      Vue.set(state, "count", count);
    },
    LOGOUT(state) {
      state.all = getDefaultState().all;
    }
  },
  actions: {
    watchBackups(context): void {
      const { commit, rootGetters } = actionContext(context);
      console.log("watchBackups", rootGetters.currentUserHasGlobalAccess);
      stopWatchingBackups();
      if (!rootGetters.currentUserHasGlobalAccess) {
        return;
      }
      backupsWatcher = firestore
        .collection("global-admin")
        .doc("backups")
        .onSnapshot(backups => {
          console.log("watchBackups", backups);
          if (!backups.exists) {
            return;
          }
          const { count } = backups.data() as { count?: number };
          commit.UPDATE_BACKUP_COUNT(count ?? 0);
        }, console.error);
    }
  }
});

export default BackupsStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, BackupsStore);
