






import Vue from "vue";
export default Vue.extend({
  name: "Core",
  created() {
    void this.$store.direct.dispatch.applyColorScheme();
  }
});
