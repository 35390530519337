





































import Vue from "vue";

export default Vue.extend({
  name: "ReferralReactor",
  props: {
    displayMode: {
      type: String,
      default: "default"
    }
  },
  computed: {
    isLight(): boolean {
      return this.displayMode === "light";
    },
    isDark(): boolean {
      return this.displayMode === "dark";
    }
  }
});
