import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { currentAppVersion } from "@/main";
import { colorSchemes } from "../uiStateConstants";

const getDefaultState = () => ({
  account: "",
  authInfoHasLoaded: false,
  extrasOpen: false,
  pageTitle: "Referral Reactor Admin Portal" as string | null,
  sortPreferences: {
    accounts: {
      fieldPath: "createdAt",
      direction: "desc"
    }
  } as Dictionary<Sort<Account.Info>>,
  preferredColorScheme: colorSchemes.default as "light" | "dark"
});

const UserInterfaceStore = defineModule({
  state: getDefaultState(),
  mutations: {
    AUTH_INFO_LOADED(state) {
      state.authInfoHasLoaded = true;
    },
    SIDENAV_OPEN(state) {
      state.extrasOpen = true;
    },
    SIDENAV_CLOSE(state) {
      state.extrasOpen = false;
    },
    SIDENAV_TOGGLE(state) {
      state.extrasOpen = !state.extrasOpen;
    },
    UPDATE_PAGE_TITLE(state, newTitle: string | null) {
      state.pageTitle = newTitle;
    },
    UPDATE_PREFERRED_COLOR_SCHEME(state, newColorScheme: "light" | "dark") {
      console.log("User wants color scheme:", newColorScheme);
      if (Object.values(colorSchemes).includes(newColorScheme)) {
        state.preferredColorScheme = newColorScheme;
      } else {
        state.preferredColorScheme = colorSchemes.default;
      }
    },
    UPDATE_SORT_PREFERENCE(
      state,
      { domain, preference }: { domain: string; preference: Sort<Account.Info> }
    ) {
      Vue.set(state.sortPreferences, domain, preference);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    openSidenav(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_OPEN();
    },
    closeSidenav(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_CLOSE();
    },
    toggleExtrasMenu(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_TOGGLE();
    },
    activateDarkMode(context): void {
      const { commit } = actionContext(context);
      commit.UPDATE_PREFERRED_COLOR_SCHEME(colorSchemes.dark);
    },
    activateLightMode(context): void {
      const { commit } = actionContext(context);
      commit.UPDATE_PREFERRED_COLOR_SCHEME(colorSchemes.light);
    },
    applyColorScheme(context): void {
      const { dispatch } = actionContext(context);
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches;
      const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
      const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addListener(e => e.matches && dispatch.activateDarkMode());
      window
        .matchMedia("(prefers-color-scheme: light)")
        .addListener(e => e.matches && dispatch.activateLightMode());

      if (isDarkMode) void dispatch.activateDarkMode();
      if (isLightMode) void dispatch.activateLightMode();
      if (isNotSpecified || hasNoSupport) {
        console.log("System color scheme not supported. Defaulting to light.");
        void dispatch.activateLightMode();
      }
    },
    async copyAndShareURL(context, url: string): Promise<void> {
      if ("clipboard" in navigator && navigator.clipboard) {
        await navigator.clipboard.writeText(url);
      }
      if ("share" in navigator && typeof navigator.share === "function") {
        await navigator.share({
          title: "Referral Reactor Invitation Code",
          text: url,
          url
        });
      }
    }
  },
  getters: {
    applicationVersionString: (): string => currentAppVersion.minor,
    pageTitle: (state): string | null => state.pageTitle
  }
});

export default UserInterfaceStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, UserInterfaceStore);
