






























import Vue from "vue";
import { skipWaiting } from "@/initializeServiceWorker";
import { auth } from "@/firebase";

import ActionButton from "@/components/ActionButton.vue";
import Logo from "@/components/Logo.vue";
import SetupPageWrapper from "@/components/SetupPageWrapper.vue";
import TextInput from "@/components/TextInput.vue";

export default Vue.extend({
  name: "Login",
  components: {
    ActionButton,
    Logo,
    SetupPageWrapper,
    TextInput
  },
  data: () => ({
    email: "",
    password: "",
    error: null as Error | string | null,
    loggingIn: false
  }),
  computed: {
    account(): string {
      return this.$store.direct.state.ui.account;
    }
  },
  methods: {
    activateNewServiceWorker(): void {
      skipWaiting();
    },
    async onSubmit(): Promise<void> {
      this.error = null;
      if (this.loggingIn === false) {
        this.loggingIn = true;
        try {
          await auth.signInWithEmailAndPassword(this.email, this.password);
          await this.$router.push("/app");
        } catch (error) {
          console.error(error);
          this.error = (error as Error).message;
        }
        this.loggingIn = false;
      }
    }
  }
});
