export function defaultAccountLimits(): Account.Limits {
  return {
    superusers: null,
    administrators: null,
    referringUsers: null,
    unlimited_superusers: false,
    unlimited_administrators: false,
    unlimited_referringUsers: false
  };
}

export function defaultAccountCounts(): Account.Counts {
  return {
    bonusPayments: 0,
    registeredUsers: {
      super: null,
      admin: null,
      normal: null
    },
    invitationCodes: 0,
    pendingInvitationsForPermission: {
      super: null,
      admin: null,
      normal: null
    },
    referrals: 0,
    totalOfBonusPaymentsAwarded: 0,
    totalOfBonusPaymentsGiven: 0,
    users: 0
  };
}
