// import store from "./store";

let waitingServiceWorker: ServiceWorker | null = null;

export function skipWaiting(): void {
  if (waitingServiceWorker) {
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }
}

export function register(): void {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in window.navigator) {
    window.navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload();
    });

    void window.navigator.serviceWorker.register("/sw.js").then(registration => {
      if (registration.waiting) {
        waitingServiceWorker = registration.waiting;
        // store.commit.UPDATE_AVAILABLE();
      }
      if (registration.active) {
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          if (newWorker?.state === "installed") {
            waitingServiceWorker = newWorker;
            // store.commit.UPDATE_AVAILABLE();
          }
          newWorker?.addEventListener("statechange", event => {
            if ((event?.target as ServiceWorker)?.state === "installed") {
              waitingServiceWorker = newWorker;
              // store.commit.UPDATE_AVAILABLE();
            }
          });
        });
      }
    });
  }
}
