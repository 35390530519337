var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'link' && _vm.to && !_vm.disabled && !_vm.isLoading)?_c('router-link',{class:['action-button', 'action-button--' + _vm.kind, 'action-button--enabled', _vm.extraClasses],attrs:{"to":_vm.to,"title":_vm.tooltip || _vm.title,"replace":_vm.replace}},[_vm._t("default",[_vm._v("Link")])],2):(_vm.type === 'link' && _vm.to)?_c('a',{class:[
    'action-button',
    'action-button--' + _vm.kind,
    ("action-button--" + (_vm.disabled ? 'disabled' : 'enabled')),
    { 'action-button--loading': _vm.isLoading },
    _vm.extraClasses
  ],attrs:{"href":"#","replace":_vm.replace,"title":_vm.tooltip || _vm.title},on:{"click":function($event){$event.preventDefault();}}},[(_vm.isLoading)?_c('LoadingIndicator',{attrs:{"show":"","white":""}}):_vm._t("default",[_vm._v("Link")])],2):(_vm.type === 'link' && _vm.href)?_c('a',{class:[
    'action-button',
    'action-button--' + _vm.kind,
    ("action-button--" + (_vm.disabled ? 'disabled' : 'enabled')),
    { 'action-button--loading': _vm.isLoading, 'action-button--fit': _vm.fit },
    _vm.extraClasses
  ],attrs:{"to":_vm.href,"download":_vm.download,"title":_vm.tooltip || _vm.title,"disabled":_vm.disabled || _vm.isLoading},on:{"click":_vm.handleHrefClick}},[(_vm.isLoading)?_c('LoadingIndicator',{attrs:{"show":"","white":""}}):_vm._t("default",[_vm._v("Link")])],2):_c('button',{class:[
    'action-button',
    'action-button--' + _vm.kind,
    { 'action-button--loading': _vm.isLoading, 'action-button--fit': _vm.fit },
    _vm.extraClasses
  ],attrs:{"type":_vm.type,"disabled":_vm.disabled,"title":_vm.tooltip || _vm.title},on:{"click":_vm.onClick}},[(_vm.isLoading)?_c('LoadingIndicator',{attrs:{"show":"","white":""}}):_vm._t("default",[_vm._v("Button")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }