import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

export const config = {
  apiKey: "AIzaSyAEPflagoag4qIcTGZs0enakaaG4F2a264",
  authDomain: "cleanup-fire-water-referrals.firebaseapp.com",
  projectId: "cleanup-fire-water-referrals",
  storageBucket: "cleanup-fire-water-referrals.appspot.com",
  messagingSenderId: "625405121654"
};

const initializedFirebase = firebase.initializeApp(config);

export const ACCOUNTS_SEARCH_INDEX = "accounts";
export const USER_SEARCH_INDEX = "users";
export const REFERRAL_SEARCH_INDEX = "referrals";

export const firestore = firebase.firestore();

export const db = firestore;
export const storage = firebase.storage();
export const auth = firebase.auth();
auth.useDeviceLanguage();

export const FieldValue = firebase.firestore.FieldValue;
export const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
export const Timestamp = firebase.firestore.Timestamp; // NOT a FieldValue

// functions
const functions = firebase.functions();

export const callableFunctions = {
  changeUserAccessLevel: functions.httpsCallable("changeUserAccessLevel"),
  createNewInvitationCode: functions.httpsCallable("createNewInvitationCode"),
  editInvitationCode: functions.httpsCallable("editInvitationCode"),
  getGlobalAdminSearchAuthKey: functions.httpsCallable("getGlobalAdminSearchAuthKey"),
  getSizeOfBackup: functions.httpsCallable("getSizeOfBackup"),
  modifyAccountLimits: functions.httpsCallable("modifyAccountLimits"),
  registerAccount: functions.httpsCallable("registerAccount"),
  runFirestoreBackup: functions.httpsCallable("runFirestoreBackup"),
  // Kaiju Calls
  enrollInKaiju: functions.httpsCallable("enrollInKaiju"),
  updateKaijuStatus: functions.httpsCallable("updateKaijuStatus"),
  addFundsToAccount: functions.httpsCallable("addFundsToAccount"),
  kaijuRequestPayoutCancellation: functions.httpsCallable("kaijuRequestPayoutCancellation")
};

if (process.env.VUE_APP_USE_FIREBASE_EMULATORS === "true") {
  console.log("Setting up Firebase services for emulation (Firestore, Functions, Auth)");
  firestore.settings({
    experimentalAutoDetectLongPolling: true
  });
  firestore.useEmulator("localhost", 8082);
  functions.useEmulator("localhost", 5001);
  auth.useEmulator("http://localhost:9099/");
}

export default initializedFirebase;
