import Vue from "vue";
import "./plugins";
import router from "./router";
import store from "./store";
import "./filters";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

Vue.config.productionTip = false;

// FIXME: There are better ways to handle versioning than this
// This object denotes the major and minor app version numbers (a la semver).
// The server expects to compare the major version to a number (i.e., is 1.5 >= 1.4)
//  when making database integrity decisions.
export const currentAppVersion = {
  major: 0.1,
  minor: "0.1.0"
};

let haveCreatedApp = false;
function createAppAndUnsubscribe() {
  new Vue({
    router,
    store: store.original,
    el: "#app",
    template: "<router-view></router-view>"
  });
  haveCreatedApp = true;
  unsubscribe();
}

const unsubscribe = store.original.watch(
  state => state.ui.authInfoHasLoaded,
  () => {
    createAppAndUnsubscribe();
  }
);

// If we don't have auth state after 3 seconds just start the app anyways.
setTimeout(() => {
  if (haveCreatedApp === false) {
    createAppAndUnsubscribe();
  }
}, 2000);

// Always remove the loading overlay after 4 seconds
setTimeout(() => {
  document.getElementById("app-loading")?.remove();
}, 3000);

void store.dispatch.watchAuthenticationState();
