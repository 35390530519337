import Vue from "vue";
import { db } from "@/firebase";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";

const getDefaultState = () => ({
  items: {} as Dictionary<CompanyInfo>
});

let companiesToWatch: WatcherCollection = {};
function resetCompanyStore() {
  Object.values(companiesToWatch).forEach(unsubscribe => unsubscribe && unsubscribe());
  companiesToWatch = {};
}

const CompanyStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_COMPANY(state, { id, company }: { id: string; company: CompanyInfo }) {
      Vue.set(state.items, id, company);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetCompanyStore();
    }
  },
  actions: {
    watchCompany(context, companyId: string): void {
      const { commit } = actionContext(context);
      if (companiesToWatch[companyId]) {
        return;
      } else {
        companiesToWatch[companyId] = db
          .collection("companies")
          .doc(companyId)
          .onSnapshot(companySnapshot => {
            const id = companySnapshot.id;
            const company = { id, ...companySnapshot.data() };
            commit.ADD_OR_UPDATE_COMPANY({ id, company });
          }, console.error);
      }
    }
  }
});

export default CompanyStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, CompanyStore);
