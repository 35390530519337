































































import Vue, { PropType, PropOptions } from "vue";

import LoadingIndicator from "./LoadingIndicator.vue";

export default Vue.extend({
  name: "ActionButton",
  components: {
    LoadingIndicator
  },
  props: {
    isLoading: { type: Boolean, default: false },
    type: { type: String, default: "button" } as PropOptions<"button" | "link">,
    kind: { type: String, default: "primary" } as PropOptions<
      | "primary"
      | "secondary"
      | "danger"
      | "danger-outline"
      | "plain"
      | "small"
      | "tiny"
      | "outline"
      | "simple"
    >,
    to: { type: String, default: "" },
    href: { type: String, default: "" },
    replace: { type: Boolean, default: false },
    download: { type: Boolean, default: false },
    extraClasses: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
    title: { type: String as PropType<string | null>, default: null },
    tooltip: { type: String as PropType<string | null>, default: null }
  },
  methods: {
    onClick(event: Event): void {
      this.$emit("click", event);
    },
    handleHrefClick(event: Event): void {
      event.preventDefault();
      window.open(this.href, "_blank");
    }
  }
});
