import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { auth, db, callableFunctions, TIMESTAMP } from "@/firebase";
import router from "@/router";

const getDefaultPermissions = () => ({
  global: false
});

const getDefaultState = () => ({
  account: null as string | null,
  firstName: null as string | null,
  lastName: null as string | null,
  displayName: null as string | null,
  paypalEmail: null as string | null,
  email: null as string | null,
  emailVerified: false,
  photoURL: null as string | null,
  uid: null as string | null,
  permissions: getDefaultPermissions(),
  providerData: {},
  firebaseToken: null as string | null,
  searchToken: null as string | null,
  searchTokenExpiration: null as number | null,
  dataLoaded: false,
  permissionsLoaded: false
});

type CurrentUserState = ReturnType<typeof getDefaultState>;

let authStateLoaded = false;
let watchers: Watcher[] = [];
function resetCurrentUserWatchers() {
  watchers.forEach(unsubscribe => unsubscribe());
  watchers = [];
}

const CurrentUserStore = defineModule({
  state: getDefaultState(),
  mutations: {
    SET_USER_PERMISSIONS(state, newPermissions: { global: boolean }) {
      state.permissions = newPermissions;
    },
    UPDATE_CURRENT_USER(state, user: Partial<CurrentUserState>) {
      Object.assign(state, user);
    },
    SIGN_IN_USER(state, user: Partial<CurrentUserState>) {
      state.displayName = user.displayName || null;
      state.email = user.email || null;
      state.emailVerified = user.emailVerified || false;
      state.photoURL = user.photoURL || null;
      state.uid = user.uid || "";
      state.providerData = user.providerData || {};
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      state.uid = "";
      resetCurrentUserWatchers();
    }
  },
  actions: {
    watchAuthenticationState(context): void {
      const { commit, rootCommit } = actionContext(context);
      auth.onAuthStateChanged(activeUser => {
        // console.log("Got auth info:", activeUser);
        // If we've logged in...
        if (activeUser) {
          commit.SIGN_IN_USER(activeUser);
          // console.log(`User ${activeUser.uid} signed in.`);

          // Logged out...
        } else if (authStateLoaded) {
          commit.LOGOUT();
          void router.push("/login");
          // console.log("User logged out.");
        }
        authStateLoaded = true;
        rootCommit.AUTH_INFO_LOADED();
      }, console.error);
    },
    watchCurrentUser(context): void {
      const { dispatch } = actionContext(context);
      void dispatch.watchCurrentUserData();
      void dispatch.watchCurrentUserPermissions();
    },
    async updateDeviceNotificationToken(context, token: string): Promise<void> {
      console.log("updateDeviceNotificationToken", token);
      const currentUserId = auth.currentUser?.uid;
      if (!currentUserId) {
        throw new Error("You're not signed in.");
      }
      await db
        .collection("users")
        .doc(currentUserId)
        .collection("devices")
        .doc(token)
        .set({ lastUsed: TIMESTAMP })
        .catch(error => console.error("Problem setting device token:", error));
    },
    async getCurrentUserData(context): Promise<void> {
      const { commit } = actionContext(context);
      const currentUserId = auth.currentUser?.uid;
      if (!currentUserId) {
        throw new Error("Not logged in.");
      }
      return db
        .collection("users")
        .doc(currentUserId)
        .get()
        .then(userSnapshot => {
          commit.UPDATE_CURRENT_USER({
            ...userSnapshot.data(),
            dataLoaded: true
          });
        });
    },
    getCurrentUserAccount(context): Promise<string> {
      const { state } = actionContext(context);
      if (state.account) {
        return Promise.resolve(state.account);
      } else {
        throw new Error("User has not selected an account");
        /*
        const currentUserId = firebase.auth.currentUser?.uid;
        const userSnapshot = await db.collection("users")
          .doc(currentUserId)
          .get();
        const user = { dataLoaded: true, ...userSnapshot.data() };
        commit(UPDATE_CURRENT_USER, user);
        if (user.account) {
          return user.account;
        } else {
          throw new Error("User has not selected an account");
        }*/
      }
    },
    watchCurrentUserData(context): void {
      const { commit } = actionContext(context);
      const currentUserId = auth.currentUser?.uid;
      if (!currentUserId) {
        return;
      }
      watchers.push(
        db
          .collection("users")
          .doc(currentUserId)
          .onSnapshot(userSnapshot => {
            commit.UPDATE_CURRENT_USER({
              ...userSnapshot.data(),
              dataLoaded: true
            });
          }, console.error)
      );
    },
    async watchCurrentUserPermissions(context): Promise<void> {
      const { state, dispatch } = actionContext(context);
      if (!state.uid) {
        throw new Error("No user is signed in.");
      }
      const permDoc = await db
        .collection("global-admin")
        .doc("administrators")
        .collection("list")
        .doc(state.uid)
        .get();
      if (permDoc.exists && permDoc.data()?.admin === true) {
        const newPermissions = {
          global: true
        };
        await dispatch.setReceivedUserPermissions(newPermissions);
      } else {
        throw new Error("No permission.");
      }
    },
    setReceivedUserPermissions(context, permissions: { global: boolean }): void {
      const { commit, rootDispatch } = actionContext(context);
      commit.SET_USER_PERMISSIONS(permissions);
      commit.UPDATE_CURRENT_USER({ permissionsLoaded: true });

      // Set watchers
      void rootDispatch.watchBackups();
      void rootDispatch.watchMessages();
      // rootDispatch.watchUsers();
    },
    async signUpUser(context, user: { uid: string; account?: string }): Promise<void> {
      const { commit } = actionContext(context);
      const userObject = { ...user, registered: true };
      await db.collection("users").doc(user.uid).set(userObject);
      commit.UPDATE_CURRENT_USER(userObject);
      if (userObject.account) {
        await db
          .collection("accounts")
          .doc(user.account)
          .collection("requestingAccess")
          .doc(user.uid)
          .set({ requestedAt: TIMESTAMP });
      }
    },
    async updateCurrentUser(context, currentUserUpdates: Partial<CurrentUserState>): Promise<void> {
      const currentUserId = auth.currentUser?.uid;
      if (!currentUserId) {
        throw new Error("The user is not signed in");
      }
      const { commit } = actionContext(context);
      await db.collection("users").doc(currentUserId).update(currentUserUpdates);
      commit.UPDATE_CURRENT_USER(currentUserUpdates);
    },
    getNewSearchToken(context): Promise<string> {
      const { commit, dispatch } = actionContext(context);
      commit.UPDATE_CURRENT_USER({
        searchToken: null,
        searchTokenExpiration: null
      });
      return dispatch.getSearchToken();
    },
    async getSearchToken(context): Promise<string> {
      const { state, commit } = actionContext(context);
      if (state.searchToken && (state.searchTokenExpiration ?? 0) > new Date().getTime()) {
        return state.searchToken;
      } else {
        const response = await callableFunctions.getGlobalAdminSearchAuthKey();
        const expiration = new Date().getTime() + 1000 * 60 * 59;
        commit.UPDATE_CURRENT_USER({
          searchToken: response.data as string,
          searchTokenExpiration: expiration
        });
        return response.data as string;
      }
    },
    async getSearchClient(context): Promise<SearchClient> {
      const { dispatch } = actionContext(context);
      try {
        const searchKey = await dispatch.getSearchToken();
        return algoliasearch("QPDMWGP68U", searchKey);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  },
  getters: {
    emailAddressForPaypal: state => state.paypalEmail || state.email,
    currentUserHasGlobalAccess: state => state.permissions.global === true
  }
});

export default CurrentUserStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, CurrentUserStore);
