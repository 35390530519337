import Vue from "vue";
import { db, auth, TIMESTAMP } from "@/firebase";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";

const getDefaultState = () => ({
  items: {} as Dictionary<Account.User>,
  numberOfUsers: 0
});

let usersToWatch: WatcherCollection = {};
function resetUserStore() {
  Object.values(usersToWatch).forEach(unsubscribe => unsubscribe && unsubscribe());
  usersToWatch = {};
}

const UserStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_USER(state, { id, user }: { id: string; user: Account.User }) {
      Vue.set(state.items, id, user);
    },
    ADD_OR_UPDATE_SEVERAL_USERS(state, newOrUpdatedUsers: Dictionary<Account.User>) {
      Vue.set(state, "items", { ...state.items, ...newOrUpdatedUsers });
    },
    FORGET_USER(state, userToRemove: string) {
      Vue.delete(state.items, userToRemove);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetUserStore();
    }
  },
  actions: {
    watchUser(context, userId: string): void {
      const { commit } = actionContext(context);
      if (!userId) {
        return;
      }
      if (usersToWatch[userId]) {
        return;
      } else {
        // console.log("watchUser", userId);
        usersToWatch[userId] = db
          .collection("users")
          .doc(userId)
          .onSnapshot(userSnapshot => {
            const { id } = userSnapshot;
            const user = { ...userSnapshot.data(), id } as Account.User;
            commit.ADD_OR_UPDATE_USER({ id, user });
          }, console.error);
      }
    },
    unwatchUser(context, userId: string): void {
      const watcher = usersToWatch[userId];
      if (watcher) {
        watcher();
        delete usersToWatch[userId];
      }
    },
    async addNote(
      context,
      {
        firestoreRef,
        newNote
      }: {
        firestoreRef: DocumentReference;
        newNote: Note;
      }
    ): Promise<void> {
      if (!auth.currentUser) {
        throw new Error("You must be signed in to add to a note");
      }
      const { title = "", text = "", contactType = "" } = newNote;
      const note: Note = {
        title,
        text,
        userId: auth.currentUser.uid,
        timestamp: TIMESTAMP,
        contactType
      };

      await firestoreRef.collection("interactions").add(note);
    },
    async editNote(
      context,
      { path, noteUpdates }: { path: string; noteUpdates: Partial<Note> }
    ): Promise<void> {
      if (!auth.currentUser) {
        throw new Error("You must be signed in to add to an note");
      }
      const { title = "", text = "", contactType = "" } = noteUpdates;
      const note: Note = {
        title,
        text,
        userId: auth.currentUser.uid,
        timestamp: TIMESTAMP,
        contactType
      };

      await db.doc(path).update(note);
    }
  }
});

export default UserStore;

type Context = Parameters<typeof moduleActionContext>[0];
const actionContext = (context: Context) => moduleActionContext(context, UserStore);
