import { defineModule } from "direct-vuex";
import { db, callableFunctions } from "@/firebase";
import { defaultKaijuInfo } from "@/typings/defaults/kaiju";

const getDefaultState = () => ({
  all: {} as Dictionary<Kaiju.Info | null>
});

async function updateKaijuStatus(
  accountId: string,
  userId: string,
  status: Kaiju.Status
): Promise<void> {
  await callableFunctions.updateKaijuStatus({
    accountId: accountId,
    userId: userId,
    statusStr: status
  });
}

const KaijuStore = defineModule({
  state: getDefaultState(),
  mutations: {},
  actions: {
    getDefaultInfo(): Kaiju.Info {
      return defaultKaijuInfo();
    },
    async enrollInKaiju(context, accountId: string): Promise<void> {
      await callableFunctions.enrollInKaiju({
        accountId: accountId
      });
    },
    async updateKaijuStatus(
      context,
      params: { accountId: string; userId: string; status: Kaiju.Status }
    ): Promise<void> {
      await callableFunctions.updateKaijuStatus({
        accountId: params.accountId,
        userId: params.userId,
        statusStr: params.status
      });
    },
    async addFundsToAccount(
      context,
      params: {
        accountId: string;
        originType: string;
        id: string;
        fundsToAdd: number;
        currency: string;
      }
    ): Promise<void> {
      await callableFunctions.addFundsToAccount({
        accountId: params.accountId,
        originType: params.originType,
        id: params.id,
        fundsToAdd: params.fundsToAdd,
        currency: params.currency
      });
    },
    async enableKaiju(context, params: { accountId: string; userId: string }): Promise<void> {
      await updateKaijuStatus(params.accountId, params.userId, "ENABLED");
    },
    async disableKaiju(context, params: { accountId: string; userId: string }): Promise<void> {
      await updateKaijuStatus(params.accountId, params.userId, "DISABLED");
    },
    async pauseKaiju(context, params: { accountId: string; userId: string }): Promise<void> {
      await updateKaijuStatus(params.accountId, params.userId, "PAUSED");
    },
    async resumeKaiju(context, params: { accountId: string; userId: string }): Promise<void> {
      await updateKaijuStatus(params.accountId, params.userId, "ENABLED");
    },
    canRequestKaijuCancellation(context, params: { type: Kaiju.PayoutStateType }): boolean {
      switch (params.type) {
        case "INITIATED":
        case "PENDING":
          return true;
        case "CANCELLED":
        case "COMPLETE":
        case "FAILED":
        case "REQUESTED_CANCELLATION":
        default:
          return false;
      }
    },
    async requestKaijiPayoutCancellation(
      context,
      params: { accountId: string; payoutId: string; note: string; userId: string }
    ): Promise<void> {
      await callableFunctions.kaijuRequestPayoutCancellation({
        accountId: params.accountId,
        payoutId: params.payoutId,
        note: params.note,
        userId: params.userId
      });
    },

    async getBonusPayment(context, params: { bPaymentId: string }): Promise<BonusPayment | null> {
      const bonusPmtData = (
        await db.collection("bonusPayments").doc(params.bPaymentId).get()
      ).data();
      if (bonusPmtData !== undefined) return bonusPmtData as BonusPayment;
      else return null;
    },
    async getReferral(context, params: { referralId: string }): Promise<Referral | null> {
      const referralData = (await db.collection("referrals").doc(params.referralId).get()).data();
      if (referralData !== undefined) return referralData as Referral;
      else return null;
    },
    async getUser(context, params: { userId: string }): Promise<User | null> {
      const userData = (await db.collection("users").doc(params.userId).get()).data();
      if (userData !== undefined) return userData as User;
      else return null;
    },
    async getPayoutState(
      context,
      params: { accountId: string; payoutId: string; stateId: string }
    ): Promise<Kaiju.PayoutState | null> {
      const stateData = (
        await db
          .collection("accounts")
          .doc(params.accountId)
          .collection("kaijuData")
          .doc("transactions")
          .collection("payouts")
          .doc(params.payoutId)
          .collection("states")
          .doc(params.stateId)
          .get()
      ).data();
      if (stateData !== undefined) return stateData as Kaiju.PayoutState;
      else return null;
    }
  }
});

export default KaijuStore;
