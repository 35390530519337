












































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TextInput",
  props: {
    dataTest: { type: String as PropType<string | null>, default: null },
    value: { type: String, default: "" },
    placeholder: { type: String as PropType<string | null>, default: null },
    type: { type: String, default: "text" },
    size: { type: Number, default: 20 },
    maxlength: { type: Number, default: 524288 },
    autofocus: { type: Boolean, default: false },
    autocomplete: { type: String, default: "" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    autocapitalize: { type: String, default: "none" },
    required: { type: Boolean, default: false }
  },
  methods: {
    onInput(event: InputEvent): void {
      const target = event.target as HTMLInputElement | null;
      this.$emit("input", target?.value);
    },
    onFocus(event: FocusEvent): void {
      this.$emit("focus", event);
    },
    onBlur(event: FocusEvent): void {
      this.$emit("blur", event);
    },
    onChange(event: InputEvent): void {
      const target = event.target as HTMLInputElement | null;
      if (target?.value !== this.value) {
        this.$emit("input", target?.value);
      }
      this.$emit("change", target?.value);
    },
    onKeyDown(event: KeyboardEvent): void {
      this.$emit("keydown", event);
    },
    onKeyUp(event: KeyboardEvent): void {
      this.$emit("keyup", event);
    },
    onKeyPress(event: KeyboardEvent): void {
      this.$emit("keypress", event);
    },
    focus(): void {
      const input = this.$refs.input as HTMLInputElement | undefined;
      input?.focus();
    }
  }
});
